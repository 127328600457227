import React, { Component } from 'react';

import { Layout, SEO, Image, HeroSlider, SlickSlider, CaseStudies, CustomersSlider, userDefault } from '../../components';
import { validateArray } from '../../components/utilities/functions';
import AOS from "aos";
import './index.scss';

class IndexPage extends Component {

	componentDidMount() {
		AOS.init({
			duration: 1000,
			disable: 'mobile',
			once: true
		});
	}

	render() {
		const { pageContext } = this.props;
		const { uid = "", locale = "", url, seo, hero_slider, case_studies, testimonials, what_we_do, customers } = (pageContext.node) || {};

		const testimonialSettings = {
			dots: false,
			arrows: false,
			infinite: true,
			autoplaySpeed: 3500,
			autoplay: true,
			slidesToShow: 2,
			slidesToScroll: 1,
			lazyLoad: true,
			responsive: [
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 1,
						dots: true,
						rtl: false
					}
				}
			]
		};

		const testimonialsArray = (
			testimonials.testimonials_list.map((card, index) =>
				<div className='slider-col' key={index} data-aos="zoom-in" data-aos-duration="2000">
					<div className='card testimonial-card' key={index}>
						<div className='text-center author-profile-pic'>
							{
								(card.thumbnail && card.thumbnail.url &&
									<Image
										data={{ image: card.thumbnail, image_alt: card.image_alt }}
										resizeWidth={70}
									/>)
								||
								<img src={userDefault} alt={card.author} />
							}
						</div>
						{
							card.author &&
							<h5 className='testimonial-author'>{card.author}</h5>
						}
						{
							card.role &&
							<p className='author-role'>{card.role}</p>
						}
						{
							card.comment &&
							<div className='author-quote' dangerouslySetInnerHTML={{ __html: card.comment }} />
						}
					</div>
				</div>
			)
		);

		const lineThroughTitle = (title, className = '') => (
			title &&
			<h2 className={'title-linethrough ' + className}><span>{title}</span></h2>
		);

		const extension = {
			contenttype:(pageContext?.contentType || ''),
			uid:uid,
			locale:locale
		}

		return (

			<Layout pageURL={url} className='home-wrapper animation-wrapper'>
				<SEO pageURL={url} {...seo} extension={extension}/>
				{
					validateArray(hero_slider) &&
					<HeroSlider data={hero_slider[0]} />
				}

				{
					customers &&
					<div className='customers-section text-center'>
						<div className='container'>
							{
								customers.description &&
								<div dangerouslySetInnerHTML={{ __html: customers.description }} />
							}
							{
								customers.cta &&
								customers.cta.link &&
								<a className='btn btn-primary btn-rounded' href={customers.cta.link}>
									{customers.cta.title}
								</a>
							}
							{
								validateArray(customers.customer_logos) &&
								<CustomersSlider
									data={customers.customer_logos}
									hasHover
									className='customers-slider'
									sliderSpeed={customers.slider_speed}
								/>
							}
						</div>
					</div>
				}
				{/* WHAT WE DO */}
				{
					what_we_do &&
					<div className='whatWeDo-section bg-grey box-100 has-top-border'>
						<div className='container'>
							{
								lineThroughTitle(what_we_do.heading)
							}
							{
								validateArray(what_we_do.cards) &&
								what_we_do.cards.map((card, index) =>
									<div className={`whatWeDo-row ${((index % 2 === 0 && 'odd-row') || 'even-row')}`} key={index}>
										<div className={'left-image'} data-aos={`${((index % 2 === 0 && 'fade-right') || 'fade-left')}`}>
											<Image
												data={card.card_image}
												className='img-100'
												resizeWidth={590}
											/>
										</div>
										<div className="right-content" data-aos={`${(index % 2 === 0 ? 'fade-left' : 'fade-right')}`}>
											<span className='card-index'>{((index < 9 && '0' + (index + 1)) || (index + 1))}</span>
											{
												card.heading &&
												<h3 className='card-title'>{card.heading}</h3>
											}
											{
												card.description &&
												<div className='card-description' dangerouslySetInnerHTML={{ __html: card.description }} />
											}
											{
												card.cta &&
												card.cta.link &&
												<a href={card.cta.link} className='link'>{card.cta.title} &rarr;</a>
											}
										</div>
									</div>
								)

							}
						</div>
					</div>
				}
				{
					case_studies &&
					case_studies.select_case_studies &&
					<CaseStudies
						data={case_studies.select_case_studies}
						heading={case_studies.heading}
						headingClass='title-linethrough'
						className='bg-grey home__CaseStudies'
					/>
				}
				{/* WORK row ends here */}

				{/* TESTIMONIALS */}
				{
					testimonials &&
					<div className='testimonials-section box-100 has-top-border'>
						<div className='container'>
							{
								lineThroughTitle(testimonials.heading)
							}
							{
								testimonials.testimonials_list &&
								(
									(testimonials.testimonials_list.length > 2 &&
										<div className='row'>
											{testimonialsArray}
										</div>
									)
									||
									<SlickSlider settings={testimonialSettings}>
										{testimonialsArray}
									</SlickSlider>
								)
							}
						</div>
					</div>
				}
			</Layout >
		)
	}
}

export default IndexPage;
